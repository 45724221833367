import { Client } from 'graphql-ws';

import { getState } from '~/store';
import {
	Data_Event_Type,
	Order_Source,
	Order_Status,
	SubscribeToVenueOrdersSubscription,
} from '~/types/__generated/gql/graphql';
import { Notification } from '~/types/store';
import {
	GetOperationalOrdersResults,
	getOperationalOrdersKey,
	initialData,
} from '../useGetOperationalOrders';
import { GetOrderByIdResults, getOrderByIdQueryKey } from '../useGetOrderById';
import { GetOrdersResults, getOrdersQueryKey } from '../useGetOrders';
import { addNewNotification } from '../useSubscribeToVenueNotifications';

const query = /* GraphQL */ `
	subscription SubscribeToVenueOrders {
		subscribeToVenueOrders {
			type
			id
			payload {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				changelogs {
					created_at
					updated_at
					user
					identifier
					old_value
					new_value
					type
				}
				user
				customer
				venue
				status
				is_paid
				payway_ref
				payway_verification_data
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				items {
					_id
					category
					item
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						comments
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
					is_paid
				}
				cancelled_items {
					_id
					category
					item
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						comments
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
					is_paid
				}
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				ticket_linebreaks

				_staff {
					_id
					first_name
					last_name
				}
				_prepared_by {
					_id
					first_name
					last_name
				}
				_processed_by {
					_id
					first_name
					last_name
				}
				_paid_by {
					_id
					first_name
					last_name
				}
				_cancelled_by {
					_id
					first_name
					last_name
				}
				_location {
					_id
					name
					type
					hash
				}
				_current_location {
					_id
					name
					type
					hash
				}
				_customer {
					_id
					first_name
					last_name
				}
				_receipt {
					_id
					payment_types {
						amount
						payment_type
						code
					}
				}
			}
		}
	}
`;

export const subscribeToVenueOrders = async (client: Client) => {
	const subscription = client.iterate<SubscribeToVenueOrdersSubscription>({
		query,
	});
	for await (const result of subscription) {
		if (result.data) {
			const { type, payload } = result.data.subscribeToVenueOrders;

			switch (type) {
				case Data_Event_Type.Create: {
					window.$queryClient?.setQueryData<GetOperationalOrdersResults>(
						getOperationalOrdersKey(),
						(oldData) => {
							if (!oldData) return initialData;
							if (!payload) return oldData;

							if (payload.status === Order_Status.Placed) {
								const newNotification: Notification = {
									id: payload._id,
									message: `New order #${payload.index}`,
									type: 'NEW_ORDER',
									severity: 'warning',
									customProps: { status: payload.status },
								};
								addNewNotification(newNotification);
							}

							return regroupOrders(payload, oldData);
						}
					);
					break;
				}
				case Data_Event_Type.Update: {
					window.$queryClient?.setQueryData<GetOperationalOrdersResults>(
						getOperationalOrdersKey(),
						(oldData) => {
							if (!oldData) return initialData;
							if (!payload) return oldData;

							return regroupOrders(payload, oldData);
						}
					);
					break;
				}
			}

			if (payload) {
				window.$queryClient?.setQueryData<GetOrderByIdResults>(getOrderByIdQueryKey(payload._id), (prev) =>
					prev ? { ...prev, ...payload } : prev
				);
				window.$queryClient?.setQueryData<GetOrdersResults>(
					getOrdersQueryKey(getState().ordersLog.filters),
					(prev) => {
						if (!prev) return { total: 1, items: [payload] };
						return {
							...prev,
							items: prev.items.map((item) => (item._id === payload._id ? payload : item)),
						};
					}
				);
			}
		}
	}
};

export const regroupOrders = (
	newOrder: GetOperationalOrdersResults['placed'][0],
	oldGroups: GetOperationalOrdersResults
) => {
	const { placed = [], preparing = [], processed = [] } = oldGroups;

	const isInPlaced = placed.find((o) => o._id === newOrder._id);
	const isInPreparing = preparing.find((o) => o._id === newOrder._id);
	const isInOthers = processed.find((o) => o._id === newOrder._id);

	const newPlaced =
		newOrder.status === Order_Status.Placed
			? isInPlaced
				? placed.map((o) => (o._id === newOrder._id ? newOrder : o))
				: [...placed, newOrder]
			: placed.filter((o) => o._id !== newOrder._id);
	const newPreparing =
		newOrder.status === Order_Status.Preparing
			? isInPreparing
				? preparing.map((o) => (o._id === newOrder._id ? newOrder : o))
				: newOrder.source === Order_Source.Qr
				? [newOrder, ...preparing]
				: [...preparing, newOrder]
			: preparing.filter((o) => o._id !== newOrder._id);
	const newProcessed =
		newOrder.status !== Order_Status.Placed && newOrder.status !== Order_Status.Preparing
			? isInOthers
				? processed.map((o) => (o._id === newOrder._id ? newOrder : o))
				: [...processed, newOrder]
			: processed.filter((o) => o._id !== newOrder._id);

	return {
		placed: newPlaced,
		preparing: newPreparing,
		processed: newProcessed,
	};
};
