import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Snackbar, Typography } from '@mui/material';
import { getToken, isSupported, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { messaging } from '~/libs/firebase';
import { useGetOperationalGeolocations } from '~/queries/useGetOperationalGeolocations';
import { useGetOperationalLocations } from '~/queries/useGetOperationalLocations';
import { useGetOperationalNotifications } from '~/queries/useGetOperationalNotifications';
import { useGetOperationalOrders } from '~/queries/useGetOperationalOrders';
import { addNewNotification } from '~/queries/useSubscribeToVenueNotifications';
import { useUpdateCurrentVenue } from '~/queries/useUpdateCurrentVenue';
import { useVenueId } from '~/store';
const PushNotification = () => {
  const venueId = useVenueId();
  const [loading, setLoading] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [initMessaging, setInitMessaging] = useState(false);
  const updateVenue = useUpdateCurrentVenue();
  const {
    refetch: refetchLocations
  } = useGetOperationalLocations(false);
  const {
    refetch: refetchGeolocations
  } = useGetOperationalGeolocations(false);
  const {
    refetch: refetchOrders
  } = useGetOperationalOrders(false);
  const {
    refetch: refetchNotifications
  } = useGetOperationalNotifications(false);
  useEffect(() => {
    async function start() {
      const isBrowserSupported = await isSupported().catch(() => false);
      if (!isBrowserSupported) return;
      setShowRequest(Notification.permission === 'default');
      if (Notification.permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: process.env.FB_VAPID_KEY
        });
        await updateVenue.mutateAsync({
          input: {
            token
          }
        }).catch(console.log);
        setInitMessaging(!!token);
      }
    }
    venueId && start();
  }, [venueId]);
  useEffect(() => {
    if (initMessaging) {
      onMessage(messaging, payload => {
        console.log('[] Received foreground message ', payload);
        const {
          data = {}
        } = payload;
        if (data.type === 'assistance_request') {
          refetchNotifications();
          addNewNotification({
            id: data.id,
            message: data.title,
            type: 'STAFF_ASSISTANCE',
            severity: 'info'
          });
        }
        if (data.type === 'order_placed') {
          refetchLocations();
          refetchGeolocations();
          refetchOrders();
          addNewNotification({
            id: data.id,
            message: data.title,
            type: 'NEW_ORDER',
            severity: 'warning',
            customProps: {
              status: data.status
            }
          });
        }
      });
    }
  }, [initMessaging]);
  const handleRequestPermission = async () => {
    try {
      setLoading(true);
      const token = await getToken(messaging, {
        vapidKey: process.env.FB_VAPID_KEY
      });
      await updateVenue.mutateAsync({
        input: {
          token
        }
      });
      setInitMessaging(!!token);
      setLoading(false);
      setShowRequest(false);
    } catch (e) {
      setLoading(false);
      setShowRequest(false);
    }
  };
  if (showRequest) return <Snackbar open autoHideDuration={3000} anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center'
  }}>
				<Paper variant="elevation" elevation={12} sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}>
					<Typography variant="body2">
						Get notified about your customers activity when app is not being used
					</Typography>
					<Box display="flex" justifyContent="flex-end" gap={1}>
						<Button variant="outlined" color="inherit" disabled={loading} onClick={() => setShowRequest(false)}>
							Dismiss
						</Button>
						<LoadingButton loading={loading} onClick={handleRequestPermission}>
							Allow notification
						</LoadingButton>
					</Box>
				</Paper>
			</Snackbar>;
  return null;
};
export { PushNotification };