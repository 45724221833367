import { useMutation, useQueryClient } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { browserStorage } from '~/libs/localforage';
import { useSetToken } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { LoginMutation, LoginMutationVariables, LoginSilentMutation } from '~/types/__generated/gql/graphql';
import { AuthResults, getAuthQueryKey } from '../useAuthCache';
import { useGetActiveMenuForPos } from '../useGetActiveMenuForPos';
import { useGetCurrentVenue } from '../useGetCurrentVenue';
import { useGetOffers } from '../useGetOffers';
import { useGetTags } from '../useGetTags';

const queryLogin = /* GraphQL */ `
	mutation Login($input: LoginInput!) {
		login(input: $input) {
			token
			role {
				_id
				type
				username
				permissions
				_user {
					_id
					first_name
					last_name
				}
			}
		}
	}
`;

const queryLoginSilent = /* GraphQL */ `
	mutation LoginSilent {
		loginSilent {
			token
			role {
				_id
				type
				username
				permissions
				_user {
					_id
					first_name
					last_name
				}
			}
		}
	}
`;

export type LoginResults = LoginMutation['login'] | LoginSilentMutation['loginSilent'];

export const useLogin = () => {
	const queryClient = useQueryClient();

	const { refetch: refetchMenu } = useGetActiveMenuForPos(false);
	const { refetch: refetchVenue } = useGetCurrentVenue(false);
	const { refetch: refetchOffers } = useGetOffers(false);
	const { refetch: refetchTags } = useGetTags(false);
	const setToken = useSetToken();

	return useMutation({
		mutationFn: (variables: LoginMutationVariables) =>
			hubGqlClient.request(graphql(queryLogin), variables).then((res) => res.login),
		onSuccess: async (data) => {
			await Promise.all([browserStorage.token.set(data.token), setToken(data.token)]);
			await Promise.all([refetchMenu(), refetchVenue(), refetchOffers(), refetchTags()]);
			queryClient.setQueryData<AuthResults>(getAuthQueryKey(), data.role);
		},
	});
};

export const useLoginSilent = () => {
	const queryClient = useQueryClient();

	const setToken = useSetToken();

	return useMutation({
		mutationFn: () => hubGqlClient.request(graphql(queryLoginSilent)).then((res) => res.loginSilent),
		onSuccess: async (data) => {
			await Promise.all([browserStorage.token.set(data.token), setToken(data.token)]);
			queryClient.setQueryData<AuthResults>(getAuthQueryKey(), data.role);
		},
	});
};
