import { Alert, Snackbar } from '@mui/material';
import { useInfo, useSetInfo } from '~/store';
const InfoAlert = () => {
  const info = useInfo();
  const setInfo = useSetInfo();
  const handleClose = () => setInfo(null);
  if (!info) return null;
  return <Snackbar open autoHideDuration={3000} onClose={handleClose} data-sentry-element="Snackbar" data-sentry-component="InfoAlert" data-sentry-source-file="InfoAlert.tsx">
			<Alert onClose={handleClose} severity="info" data-sentry-element="Alert" data-sentry-source-file="InfoAlert.tsx">
				{info}
			</Alert>
		</Snackbar>;
};
export { InfoAlert };