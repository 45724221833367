import { tokens } from '../tokens';

export const en = {
	[tokens.sidePanel.ops]: 'Operations',
	[tokens.sidePanel.inventories]: 'Inventory Management',
	[tokens.sidePanel.menus]: 'Menu Management',
	[tokens.sidePanel.receipts]: 'Receipt Management',
	[tokens.sidePanel.orders]: 'Order Management',
	[tokens.sidePanel.reports]: 'Reports & Analytics',
	[tokens.sidePanel.customers]: 'Customer Management',
	[tokens.sidePanel.offers]: 'Offers Management',
	[tokens.sidePanel.purchase]: 'Purchase Management',
	[tokens.sidePanel.settings]: 'Settings',
	[tokens.searchBar.orderNumber]: 'Order Number',
	[tokens.searchBar.locationName]: 'Locaiton Name',
	[tokens.searchBar.itemName]: 'Item Name',
	[tokens.nav.till]: 'Till',
	[tokens.nav.orders]: 'Orders',
	[tokens.nav.locations]: 'Locations',
	[tokens.nav.kds]: 'KDS',
	[tokens.nav.reservations]: 'Reservations',
	[tokens.ops.orders.placed]: 'Placed',
	[tokens.ops.orders.preparing]: 'Preparing',
	[tokens.ops.orders.processed]: 'Processed',
	[tokens.ops.orders.unpaid]: 'UNPAID',
	[tokens.ops.orders.paid]: 'PAID',
	[tokens.ops.orders.d]: 'd',
	[tokens.ops.orders.h]: 'h',
	[tokens.ops.orders.items]: 'ITEMS',
	[tokens.ops.orders.cancelledItems]: 'CANCELLED ITEMS',
	[tokens.ops.orders.of]: 'of',
	[tokens.ops.orders.prepared]: 'prepared',
	[tokens.ops.orders.orderCreated]: 'Order Created',
	[tokens.ops.orders.movedToPreparing]: 'Moved to preparing',
	[tokens.ops.orders.customerAcceptPrepTime]: 'Customer accepted prep time updates',
	[tokens.ops.orders.customerRejectPrepTime]: 'Customer rejected prep time updates',
	[tokens.ops.orders.prepTimeUpdate]: 'Prep time was updated',
	[tokens.ops.orders.autoCancelledAllItems]: 'Order automatically cancelled since all items were cancelled',
	[tokens.ops.orders.autoCancelledAllSplitItems]:
		'Order automatically cancelled as a result of all items being splitted',
	[tokens.ops.orders.autoCancelledAllMovedItems]:
		'Order automatically cancelled as a result of all items being moved to new location',
	[tokens.ops.orders.quantityChangeFrom]: 'quantity changed from',
	[tokens.ops.orders.quantityChangeTo]: 'to',
	[tokens.ops.orders.cancelled]: 'cancelled',
	[tokens.ops.orders.discountChangeFrom]: 'Order discount changed from',
	[tokens.ops.orders.discountChangeTo]: 'to',
	[tokens.ops.orders.splitOf]: 'of',
	[tokens.ops.orders.splitAll]: 'All',
	[tokens.ops.orders.splited]: 'were splitted',
	[tokens.ops.orders.moveOf]: 'of',
	[tokens.ops.orders.moveAll]: 'All',
	[tokens.ops.orders.moved]: 'were moved to new location',
	[tokens.ops.orders.movedToProcessed]: 'Move to processed',
	[tokens.ops.orders.markedAsPaid]: 'Marked as paid by',
	[tokens.ops.orders.orderCancelled]: 'Order cancelled',
	[tokens.ops.orders.add]: 'Add',
	[tokens.ops.orders.edit]: 'Edit',
	[tokens.ops.orders.takePayment]: 'Take Payment',
	[tokens.ops.orders.processOrder]: 'Process Order',
	[tokens.ops.orders.prepareOrder]: 'Prepare Order',
	[tokens.ops.orders.printKitchenMessage]: 'Print Kitchen Message',
	[tokens.ops.orders.printBill]: 'Print Bill',
	[tokens.ops.orders.printTicket]: 'Print Tickets',
	[tokens.ops.orders.printChecklist]: 'Print Checklists',
	[tokens.ops.orders.printCancelledTicket]: 'Print Cancelled Ticket',
	[tokens.ops.orders.kitchenMessageContent]: 'Kitchen Message Content',
	[tokens.ops.orders.messageIsRequired]: 'Message is required',
	[tokens.ops.orders.cancelOrder]: 'Cancel Order',
	[tokens.ops.orders.cancelOrderModel]: 'Cancel Order?',
	[tokens.ops.orders.cancelOrderModelDesc]:
		'When an order is cancelled, you cannot change it status again, unless the a new order is created. Add a reason for the cancellation below.',
	[tokens.ops.orders.subTotal]: 'Subtotal:',
	[tokens.ops.orders.offer]: 'Offer:',
	[tokens.ops.orders.discount]: 'Discount:',
	[tokens.ops.orders.vat]: 'VAT:',
	[tokens.ops.orders.service]: 'Service:',
	[tokens.ops.orders.adjustment]: 'Adjustment:',
	[tokens.ops.orders.grandTotal]: 'Grand Total:',
	[tokens.ops.orders.locationName]: 'Location Name',
	[tokens.ops.locations.showAllLocation]: 'Showing All Locations',
	[tokens.ops.locations.only]: 'Only',
	[tokens.ops.locations.showing]: 'Showing',
	[tokens.ops.locations.locations]: 'Locations',
	[tokens.ops.locations.switchToListView]: 'Switch to list view',
	[tokens.ops.locations.switchToFloorPlan]: 'Switch to floorplan view',
	[tokens.ops.locations.createNewLocation]: 'Create new location',
	[tokens.ops.locations.editLayout]: 'Edit Layout',
	[tokens.ops.locations.locationAvailable]: 'This location is available',
	[tokens.ops.locations.addItem]: 'Add Items',
	[tokens.ops.locations.markedOccupied]: 'Mark as occupied',
	[tokens.ops.locations.markedAvailable]: 'Mark available',
	[tokens.ops.locations.defaultOrderNote]: 'Location Comment',
	[tokens.ops.locations.editThisLocation]: 'Edit this location',
	[tokens.ops.locations.openConsumerApp]: 'Open in consumer app',
	[tokens.ops.locations.getOrderQR]: 'Get ordering QR',
	[tokens.ops.locations.deleteThisLocation]: 'Delete this location',
	[tokens.ops.locations.totalVat]: 'Total vat',
	[tokens.ops.locations.totalService]: 'Total service',
	[tokens.ops.locations.totalBill]: 'Total bill',
	[tokens.ops.locations.totalPaid]: 'Total paid',
	[tokens.ops.locations.totalUnpaid]: 'Total unpaid',
	[tokens.ops.locations.moveOrders]: 'Move Orders',
	[tokens.ops.locations.moveItems]: 'Move Items',
	[tokens.ops.locations.printChecklist]: 'Print Checklist',
	[tokens.ops.locations.split]: 'Split',
	[tokens.ops.locations.kitchenMessage]: 'Kitchen Message',
	[tokens.ops.locations.sendKitchenMessage]: 'Send Kitchen Message',
	[tokens.ops.locations.addObjectToLayout]: 'Add Object to Layout',
	[tokens.ops.locations.doneEditLayout]: 'Done Editing Layout',
	[tokens.ops.locations.printBill]: 'Print Bill',
	[tokens.ops.locations.selectNewLocation]: 'Select a new location',
	[tokens.ops.locations.categoryDisableMessage]:
		'Selected categories will be hidden from digital menu, but will remain visible in POS',
	[tokens.ops.locations.itemTypeDisableMessage]:
		'Selected item types will be hidden from digital menu, but will remain visible in POS',
	[tokens.ops.locations.itemDisableMessage]:
		'Selected items will be hidden from digital menu, but will remain visible in POS',
	[tokens.ops.locations.payNowMustBeAllowedMessage]: 'Pay Now must be allowed to select payment types',
	[tokens.ops.locations.allowPayNow]: 'Allow pay now for this location?',
	[tokens.ops.locations.allowPayLater]: 'Allow pay now for this location?',
	[tokens.ops.locations.moveOrderOutOfPrefix]: 'Move orders out of',
	[tokens.ops.locations.moveOrderOutOfSuffix]: '',
	[tokens.ops.locations.moveItemOutOfPrefix]: 'Move items out of this location',
	[tokens.ops.locations.moveItemOutOfSuffix]: '',
	[tokens.ops.locations.newLocationIdRequired]: 'new locationID is a required field',
	[tokens.ops.locations.allOrders]: 'All Orders',
	[tokens.ops.locations.howDoYouWantToSplitThisTab]: 'How do you want to split this tab?',
	[tokens.ops.locations.equally]: 'Equally',
	[tokens.ops.locations.amoungHowManyPeopleWillThisTabBeSplitted]:
		'Among how many people will this tab be splitted',
	[tokens.ops.locations.eachPersonPays]: 'Each person pays',
	[tokens.ops.locations.byItems]: 'By Items',
	[tokens.ops.locations.selectItemsTosplitIntoNewOrders]: 'Select items to split into new order',
	[tokens.ops.locations.order]: 'Order',
	[tokens.ops.locations.unpaid]: 'Unpaid',
	[tokens.ops.locations.splitIntoNewOrder]: 'Split into new order',
	[tokens.ops.tills.createItem]: 'Create Item',
	[tokens.ops.tills.itemType]: 'Item Types',
	[tokens.ops.tills.paymentMode]: 'Payment mode',
	[tokens.ops.tills.changeToTab]: 'Charge to tab',
	[tokens.ops.tills.payNow]: 'Pay now',
	[tokens.ops.tills.paid]: 'Paid',
	[tokens.ops.tills.clear]: 'Clear',
	[tokens.ops.tills.options]: 'Options',
	[tokens.ops.tills.listedPrice]: 'Listed Price (per Unit)',
	[tokens.ops.tills.itemComments]: 'Item Comments',
	[tokens.ops.tills.cancel]: 'Cancel',
	[tokens.ops.tills.save]: 'Save',
	[tokens.ops.tills.customer]: 'Customer',
	[tokens.ops.tills.kitchenMessage]: 'Send',
	[tokens.ops.tills.lowestDenominator]: 'Lowest Denominator',
	[tokens.ops.tills.addHeadCount]: 'Add Headcount',
	[tokens.ops.tills.headCount]: 'Headcount',
	[tokens.ops.tills.selectPaymentType]: 'Select Payment Type',
	[tokens.ops.tills.addTips]: 'Add Tips',
	[tokens.ops.tills.paymentTypeUsed]: 'Payment type used',
	[tokens.ops.tills.changeCalculator]: 'Change Calculator',
	[tokens.ops.tills.totalRecieved]: 'Total Received',
	[tokens.ops.tills.totalCashPayment]: 'Total Cash Payment',
	[tokens.ops.tills.change]: 'Change',
	[tokens.ops.tills.locationIsRequired]: 'Location is required',
	[tokens.ops.tills.optionGroups]: 'Option Groups',
	[tokens.ops.tills.maximumSelect]: 'Maximum Select',
	[tokens.ops.tills.required]: 'Required',
	[tokens.ops.tills.nonRequired]: 'Non-Required',
	[tokens.ops.tills.grandTotal]: 'Grand Total',
	[tokens.ops.tills.courseSeperator]: 'Course Separator',
	[tokens.ops.tills.showMore]: 'Show More',
	[tokens.ops.tills.showLess]: 'Show Less',
	[tokens.ops.tills.cancelledThisItem]: 'Cancel this item?',
	[tokens.ops.tills.cancelledThisItemDesc]:
		'When an item is cancelled, you cannot uncancel it. But you can edit or add this item again to this order from till. Add a reson for the cancellation below.',
	[tokens.ops.tills.tips]: 'Tip',
	[tokens.ops.tills.tipModelTitleBeforeAmount]: 'Payment amount is greater than grand total.',
	[tokens.ops.tills.tipModelTitleAfterAmount]: 'amount will be added to tips',
	[tokens.ops.tills.headcountRequiredBeforeTakePayment]: 'Require headcount before payment',
	[tokens.ops.tills.invalidPaymentAmount]: 'Invalid Payment Amount',
	[tokens.ops.tills.selectTip]: 'Select Tips',
	[tokens.reports.nav.shift]: 'Shift',
	[tokens.reports.nav.from]: 'From',
	[tokens.reports.nav.to]: 'To',
	[tokens.reports.nav.generate]: 'Generate',
	[tokens.reports.nav.export]: 'Export',
	[tokens.reports.nav.summary]: 'Summary',
	[tokens.reports.nav.receipts]: 'Receipts',
	[tokens.reports.nav.categories]: 'Categories',
	[tokens.reports.nav.items]: 'Items',
	[tokens.reports.nav.locations]: 'Locations',
	[tokens.reports.nav.staffs]: 'Staffs',
	[tokens.reports.nav.details]: 'Details',
	[tokens.reports.nav.inventory]: 'Inventory',
	[tokens.menus.menuManagement]: 'Menu Management',
	[tokens.menus.menus]: 'Menus',
	[tokens.menus.createNewMenu]: 'Create New Menu',
	[tokens.menus.updateMenu]: 'Update Menu',
	[tokens.menus.logo]: 'Logo',
	[tokens.menus.name]: 'Name',
	[tokens.menus.description]: 'Description',
	[tokens.menus.visible]: 'Visible',
	[tokens.menus.view]: 'View',
	[tokens.menus.edit]: 'Edit',
	[tokens.menus.viewItems]: 'View Items',
	[tokens.menus.viewOptions]: 'View Options',
	[tokens.menus.copyExistingCategory]: 'Copy existing category',
	[tokens.menus.copyThisCategory]: 'Copy this category',
	[tokens.menus.createNewCategory]: 'Create new category',
	[tokens.menus.copyFromExistingItem]: 'Copy from existing item',
	[tokens.menus.copyThisItem]: 'Copy this item',
	[tokens.menus.createNewItem]: 'Create new item',
	[tokens.menus.selectACategoryFirstBeforeCloningItem]: 'Select a category first before cloning item',
	[tokens.menus.categoryName]: 'Category Name',
	[tokens.menus.price]: 'Price',
	[tokens.menus.type]: 'Type',
	[tokens.menus.category]: 'Category',
	[tokens.menus.pos]: 'POS',
	[tokens.menus.digitalMenu]: 'Digital Menu',
	[tokens.menus.categoryTitle]: 'Category Title',
	[tokens.menus.categoryIsARequiredField]: 'Category is a required field',
	[tokens.menus.titleIsARequiredField]: 'Title is a required field',
	[tokens.menus.displayInDigitalMenu]: 'Display in digital menu?',
	[tokens.menus.displayInTill]: 'Display in till?',
	[tokens.menus.printer]: 'Printer',
	[tokens.menus.printerTagIsARequiredField]: 'Printer_tag is a required field',
	[tokens.menus.typeIsARequiredField]: 'Type is a required field',
	[tokens.menus.title]: 'Title',
	[tokens.menus.originalPrice]: 'Original Price',
	[tokens.menus.prepTime]: 'Prep Time',
	[tokens.menus.extraQuantity]: 'Extra Quantity',
	[tokens.menus.listedPrice]: 'Listed Price',
	[tokens.menus.addOption]: 'Add Option',
	[tokens.menus.copyOption]: 'Copy Option',
	[tokens.menus.copyThisOption]: 'Copy this option',
	[tokens.menus.copyOptionToItem]: 'Copy option to item',
	[tokens.menus.addOptionGroup]: 'Add Option Group',
	[tokens.menus.copyOptionGroup]: 'Copy Option Group',
	[tokens.menus.addOptionGroupToItem]: 'Add option group to the item',
	[tokens.menus.copyOptionGroupToItem]: 'Copy option group to the item',
	[tokens.menus.newOptionsGroup]: 'New Options Group',
	[tokens.menus.copyThisOptionGroup]: 'Copy this option group',
	[tokens.menus.copyFromExistingOptionGroup]: 'Copy from existing option group',
	[tokens.menus.copyFromExistingOptions]: 'Copy from existing options',
	[tokens.menus.optionGroupName]: 'Option Group Name',
	[tokens.menus.updateOptionGroup]: 'Update option group',
	[tokens.menus.addFromExistingOption]: 'Add from existing option',
	[tokens.menus.removeThisOption]: 'Remove this option',
	[tokens.menus.optionTitle]: 'Option Title',
	[tokens.menus.updateItem]: 'Update Item',
	[tokens.menus.itemOptions]: 'Item Options',
	[tokens.menus.itemOptionGroups]: 'Item Option Groups',
	[tokens.menus.manageItemRecipe]: 'Manage Item Recipe',
	[tokens.menus.selectOptions]: 'Select options',
	[tokens.menus.selectOptionGroups]: 'Select option groups',
	[tokens.menus.updateRecipeToMenuOption]: 'Update recipe to menu option',
	[tokens.menus.updateItemOptionGroup]: 'Update item option group',
	[tokens.menus.requiredOption]: 'Required option',
	[tokens.menus.minimumSelect]: 'Minimum Select',
	[tokens.menus.maximumSelect]: 'Maximum Select',
	[tokens.menus.addThisOption]: 'Add this option',
	[tokens.menus.newOption]: 'New Option',
	[tokens.menus.createNewOptionGroup]: 'Create new option group',
	[tokens.menus.createNewOption]: 'Create new option',
	[tokens.menus.updateOption]: 'Update Option',
	[tokens.menus.optionGroupTitle]: 'Option Group Title',
	[tokens.menus.optionName]: 'Name',
	[tokens.menus.optionPrice]: 'Price',
	[tokens.menus.duplicateOptionName]: 'Duplicate Option Name',
	[tokens.menus.duplicateOptionGroupName]: 'Duplicate Option Group Name',
	[tokens.menus.youAreAboutToDeleteThisOption]: 'You are about to delete this option',
	[tokens.menus.vat]: 'VAT',
	[tokens.menus.serviceCharge]: 'Service Charge',
	[tokens.menus.autoSelectOption]: 'Auto-Select Option',
	[tokens.menus.minimumRequiredPrice]: 'Minimum Required Price',
	[tokens.reports.summary.tableView]: 'Table View',
	[tokens.reports.summary.chartView]: 'Chart View',
	[tokens.reports.summary.subtotalPrice]: 'Subtotal Price',
	[tokens.reports.summary.discount]: 'Discount',
	[tokens.reports.summary.offers]: 'Offers',
	[tokens.reports.summary.netSales]: 'Net Sales',
	[tokens.reports.summary.serviceCharge]: 'Service Charge',
	[tokens.reports.summary.vat]: 'VAT',
	[tokens.reports.summary.grossSales]: 'Gross Sales',
	[tokens.reports.summary.adjustment]: 'Adjustment',
	[tokens.reports.summary.cancelled]: 'Cancelled',
	[tokens.reports.summary.roundingAmount]: 'Rounding Amount',
	[tokens.reports.summary.paymentsReceived]: 'Payments Received',
	[tokens.reports.summary.salesByDays]: 'Sales by Days',
	[tokens.reports.summary.columns]: 'Columns', //1
	[tokens.reports.summary.filters]: 'Filters',
	[tokens.reports.summary.density]: 'Density',
	[tokens.reports.summary.findColumn]: 'Find Column',
	[tokens.reports.summary.columnTitle]: 'Column Title',
	[tokens.reports.summary.hideAll]: 'Hide All',
	[tokens.reports.summary.showAll]: 'Show All',
	[tokens.reports.summary.operator]: 'Operator',
	[tokens.reports.summary.value]: 'Value',
	[tokens.reports.summary.is]: 'Is',
	[tokens.reports.summary.isNot]: 'Is not',
	[tokens.reports.summary.isAfter]: 'Is after',
	[tokens.reports.summary.isOnOrAfter]: 'Is on or after',
	[tokens.reports.summary.isBefore]: 'Is before',
	[tokens.reports.summary.isOnOrBefore]: 'Is on or before',
	[tokens.reports.summary.clear]: 'Clear',
	[tokens.reports.summary.today]: 'Today',
	[tokens.reports.summary.equals]: 'Equals',
	[tokens.reports.summary.notEqual]: 'Not equal',
	[tokens.reports.summary.greaterThan]: 'Greater than',
	[tokens.reports.summary.greaterThanOrEqual]: 'Greater than or equal',
	[tokens.reports.summary.lessThan]: 'Less than',
	[tokens.reports.summary.lessThanOrEqual]: 'Less than or equal',
	[tokens.reports.summary.isAnyOf]: 'Is any of',
	[tokens.reports.summary.date]: 'Date',
	[tokens.reports.summary.offerAmount]: 'Offer Amount',
	[tokens.reports.summary.discountAmount]: 'Discount Amount',
	[tokens.reports.summary.cancelledAmount]: 'Cancelled Amount',
	[tokens.reports.summary.avgOrderAmount]: 'Avg Order Amount',
	[tokens.reports.summary.receiptsCount]: 'Receipts Count',
	[tokens.reports.summary.averageReceiptAmount]: 'Average Receipt Amount',
	[tokens.reports.summary.compact]: 'Compact',
	[tokens.reports.summary.standard]: 'Standard',
	[tokens.reports.summary.comfortable]: 'Comfortable',
	[tokens.reports.summary.downloadAsCsv]: 'Download as CSV',
	[tokens.reports.summary.print]: 'Print', //1
	[tokens.reports.summary.beforeYouProceed]: 'Before you proceed',
	[tokens.reports.summary.processTakesAwhileMessage]:
		"This process may take a while since we'll need to gather and calculate all your sdales data within the specified date range that you set. The longer the date range you set, the longer the process will take.",
	[tokens.reports.summary.printerForReportIsAvailable]: 'Printer for "report" is available',
	[tokens.reports.summary.printerForReportIsNotAvailable]: 'No `report` printer is available.',
	[tokens.reports.summary.printServiceIsOnlineMessage]:
		'Print service is online. Keep it online till the reports is printed.',
	[tokens.reports.summary.printServiceIsOfflineMessage]:
		'Print service is offline. Please start your print service.',
	[tokens.reports.summary.setupHere]: '',
	[tokens.reports.summary.selectTheTypeOfReportsForExport]: 'Select the type of reports you want to export:',
	[tokens.reports.summary.salesSummaryReports]: 'Sales Summary Reports',
	[tokens.reports.summary.itemsSalesReports]: 'Items Sales Reports',
	[tokens.reports.summary.printOnlyItemsWithSales]: 'Print only items with sales?',
	[tokens.reports.summary.paymentTypes]: 'Payment Types',
	[tokens.reports.summary.locationGroupTags]: 'Location Group Tags',
	[tokens.reports.summary.downloadSoftCopy]: 'Download Soft Copy',
	[tokens.reports.receipts.avgPaymentPerReceipt]: 'Avg Payment per Receipt',
	[tokens.reports.receipts.receiptCount]: 'Receipt Count',
	[tokens.reports.receipts.nonVoidReceipt]: 'Non-Void Receipt',
	[tokens.reports.receipts.voidReceipt]: 'Void Receipt',
	[tokens.reports.receipts.byReceiptTypes]: 'By Receipt Types',
	[tokens.reports.receipts.internalReceipts]: 'Internal Receipts',
	[tokens.reports.receipts.externalReceipts]: 'External Receipts',
	[tokens.reports.receipts.allBlank]: 'All Blank',
	[tokens.reports.receipts.addFilter]: 'Add Filter',
	[tokens.reports.receipts.year]: 'Year',
	[tokens.reports.receipts.month]: 'Month',
	[tokens.reports.receipts.date]: 'Date',
	[tokens.reports.receipts.hour]: 'Hour',
	[tokens.reports.receipts.minute]: 'Minute',
	[tokens.reports.receipts.receipt]: 'Receipt #',
	[tokens.reports.receipts.orders]: 'Orders #',
	[tokens.reports.receipts.locationTypes]: 'Location Types',
	[tokens.reports.receipts.locationName]: 'Location Name',
	[tokens.reports.receipts.staff]: 'Staff',
	[tokens.reports.receipts.status]: 'Status',
	[tokens.reports.receipts.subtotalPrice]: 'Subtotal Price',
	[tokens.reports.receipts.offerName]: 'Offer Name',
	[tokens.reports.receipts.offerAmount]: 'Offer Amount',
	[tokens.reports.receipts.discountAmount]: 'Discount Amount',
	[tokens.reports.receipts.netSales]: 'Net Sales',
	[tokens.reports.receipts.vat]: 'VAT',
	[tokens.reports.receipts.serviceCharge]: 'Service Charge',
	[tokens.reports.receipts.grossAmount]: 'Gross Amount',
	[tokens.reports.receipts.roundingAmt]: 'Rounding Amt',
	[tokens.reports.receipts.adjustmentAmt]: 'Adjustment Amt',
	[tokens.reports.receipts.cancelledAmount]: 'Cancelled Amount',
	[tokens.reports.receipts.paymentTypes]: 'Payment Types',
	[tokens.reports.receipts.paymentsReceived]: 'Payments Received',
	[tokens.reports.receipts.customers]: 'Customers',
	[tokens.reports.receipts.headcount]: 'Headcount',
	[tokens.reports.receipts.tips]: 'Tips',
	[tokens.reports.receipts.shift]: 'Shift',
	[tokens.reports.receipts.paywayrefs]: 'PayWayRef(s)',
	[tokens.reports.categories.total]: 'Total',
	[tokens.reports.categories.top10ByRevenue]: 'Top 10 By Revenue',
	[tokens.reports.categories.top10BySoldItems]: 'Top 10 by Sold Items',
	[tokens.reports.categories.categoryName]: 'Category Name',
	[tokens.reports.categories.soldItems]: 'Sold Items',
	[tokens.reports.categories.revenue]: 'Revenue',
	[tokens.reports.categories.title]: 'Title',
	[tokens.reports.categories.subtotal]: 'Subtotal',
	[tokens.reports.categories.discount]: 'Discount',
	[tokens.reports.categories.offer]: 'Offer',
	[tokens.reports.categories.netSales]: 'Net Sales',
	[tokens.reports.categories.serviceCharge]: 'Service Charge',
	[tokens.reports.categories.vat]: 'VAT',
	[tokens.reports.categories.grossSales]: 'Gross Sales',
	[tokens.reports.categories.cancelledValue]: 'Cancelled Value',
	[tokens.reports.categories.sort]: 'Sort',
	[tokens.reports.categories.unsort]: 'Unsort',
	[tokens.reports.categories.sortByAsc]: 'Sort by Asc',
	[tokens.reports.categories.sortByDesc]: 'Sort by Desc',
	[tokens.reports.categories.filters]: 'Filters',
	[tokens.reports.categories.hideAll]: 'Hide All',
	[tokens.reports.categories.showColumns]: 'Show Columns',
	[tokens.reports.categories.pinToLeft]: 'Pin to left',
	[tokens.reports.categories.pinToRight]: 'Pin to right',
	[tokens.reports.items.revenueByItemTypes]: 'Revenue by Item Types',
	[tokens.reports.items.soldItemsByItemTypes]: 'Sold Items by Item Types',
	[tokens.reports.items.itemType]: 'Item Type',
	[tokens.reports.items.revenue]: 'Revenue',
	[tokens.reports.items.itemTitle]: 'Item Title',
	[tokens.reports.items.optionTitle]: 'Option Title',
	[tokens.reports.items.categoryTitle]: 'Category Title',
	[tokens.reports.items.subtotal]: 'Subtotal',
	[tokens.reports.items.netAmount]: 'Net Amount',
	[tokens.reports.items.total]: 'Total',
	[tokens.reports.items.soldItem]: 'Sold Item',
	[tokens.reports.items.discount]: 'Discount',
	[tokens.reports.items.offer]: 'Offer',
	[tokens.reports.items.serviceCharge]: 'Service Charge',
	[tokens.reports.items.vat]: 'VAT',
	[tokens.reports.items.grossSales]: 'Gross Sales',
	[tokens.reports.items.cancelledValue]: 'Cancelled Value',
	[tokens.reports.locations.mostPopular]: 'Most Popular',
	[tokens.reports.locations.topMoneyMaker]: 'Top Money Maker',
	[tokens.reports.locations.receivedPaymentsAll]: 'Received Payments (All)',
	[tokens.reports.locations.salesByLocationGroupAll]: 'Sales by Location Group (All)',
	[tokens.reports.locations.locationTypes]: 'Location Types',
	[tokens.reports.locations.paymentTypes]: 'Payment Types',
	[tokens.reports.locations.showingAllLocations]: 'Showing All Locations',
	[tokens.reports.locations.onlyXLocations]: 'Only "x" Locations',
	[tokens.reports.locations.locationNames]: 'Location Names',
	[tokens.reports.locations.ordersCount]: 'Orders Count',
	[tokens.reports.locations.receiptCount]: 'Receipt Count',
	[tokens.reports.locations.headcount]: 'Headcount',
	[tokens.reports.locations.receiptsAmount]: 'Receipts Amount',
	[tokens.reports.locations.subtotalPrice]: 'Subtotal Price',
	[tokens.reports.locations.offerAmount]: 'Offer Amount',
	[tokens.reports.locations.discountAmount]: 'Discount Amount',
	[tokens.reports.locations.netSales]: 'Net Sales',
	[tokens.reports.locations.vatAmount]: 'VAT Amount',
	[tokens.reports.locations.serviceChargeAmount]: 'Service Charge Amount',
	[tokens.reports.locations.grossAmount]: 'Gross Amount',
	[tokens.reports.locations.roundingAmt]: 'Rounding Amt',
	[tokens.reports.locations.adjustmentAmount]: 'Adjustment Amount',
	[tokens.reports.locations.cancelledAmount]: 'Cancelled Amount',
	[tokens.reports.locations.byPaymentTypes]: 'By Payment Types',
	[tokens.reports.staffs.topPerformerByOrdersCount]: 'Top Performer By Orders Count',
	[tokens.reports.staffs.ordersCount]: 'Orders Count',
	[tokens.reports.staffs.ordersGrossSales]: 'Orders Gross Sales',
	[tokens.reports.staffs.topPerformerByOrdersGrossSales]: 'Top Performer by Orders Gross Sales',
	[tokens.reports.staffs.staffName]: 'Staff Name',
	[tokens.reports.details.grossAmount]: 'Gross Amount',
	[tokens.reports.details.qr]: 'QR',
	[tokens.reports.details.till]: 'Till',
	[tokens.reports.details.averageOrderAmount]: 'Average Order Amount',
	[tokens.reports.details.ordersCount]: 'Orders Count',
	[tokens.reports.details.year]: 'Year',
	[tokens.reports.details.month]: 'Month',
	[tokens.reports.details.date]: 'Date',
	[tokens.reports.details.hour]: 'Hour',
	[tokens.reports.details.minute]: 'Minute',
	[tokens.reports.details.receipt]: 'Receipt#',
	[tokens.reports.details.orders]: 'Orders #',
	[tokens.reports.details.locationTypes]: 'Location Types',
	[tokens.reports.details.locationName]: 'Location Name',
	[tokens.reports.details.orderThrough]: 'Order Through',
	[tokens.reports.details.staffName]: 'Staff Name',
	[tokens.reports.details.category]: 'Category',
	[tokens.reports.details.itemType]: 'Item Type',
	[tokens.reports.details.item]: 'Item',
	[tokens.reports.details.status]: 'Status',
	[tokens.reports.details.isPaid]: 'Is Paid',
	[tokens.reports.details.isCancelled]: 'Is Cancelled',
	[tokens.reports.details.qty]: 'Qty',
	[tokens.reports.details.unitPrice]: 'Unit Price',
	[tokens.reports.details.subtotalPrice]: 'Subtotal Price',
	[tokens.reports.details.offerName]: 'Offer Name',
	[tokens.reports.details.offerAmount]: 'Offer Amount',
	[tokens.reports.details.discountAmount]: 'Discount Amount',
	[tokens.reports.details.netSales]: 'Net Sales',
	[tokens.reports.details.vatIncluded]: 'VAT (*included)',
	[tokens.reports.details.scIncluded]: 'Service Charge (*included)',
	[tokens.reports.details.adjustmentAmount]: 'Adjustment Amount',
	[tokens.reports.details.cancelledAmount]: 'Cancelled Amount',
	[tokens.reports.details.cancelledReason]: 'Cancelled Reason',
	[tokens.reports.details.finalPaymentType]: 'Final Payment Type',
	[tokens.reports.details.customers]: 'Customers',
	[tokens.reports.details.shift]: 'Shift',
	[tokens.reports.details.paywayRef]: 'PayWay Ref',
	[tokens.reports.inventory.liveData]: 'Live Data',
	[tokens.reports.inventory.historicalData]: 'Historical Data',
	[tokens.reports.inventory.totalInventoryBalanceValue]: 'Total Inventory Balance Value',
	[tokens.reports.inventory.top10MostUtilisedRawMaterialsBalanceValue]:
		'Top 10 Most Utilised Raw Materials Balance Value',
	[tokens.reports.inventory.top10LeastUtilisedRawMaterialsBalanceValue]:
		'Top 10 Least Utilised Raw Materials Balance value',
	[tokens.reports.inventory.top10MostUtilised]: 'Top 10 Most Utilised',
	[tokens.reports.inventory.top10LeastUtilised]: 'Top 10 Least Utilised',
	[tokens.reports.inventory.name]: 'Name',
	[tokens.reports.inventory.balanceQty]: 'Balance Qty',
	[tokens.reports.inventory.balanceValue]: 'Balance Value',
	[tokens.reports.inventory.rawMaterialName]: 'Raw Material Name',
	[tokens.reports.inventory.rawMaterialCode]: 'Raw Material Code',
	[tokens.reports.inventory.receivedUom]: 'Received UoM',
	[tokens.reports.inventory.totalReceivedQty]: 'Total Received Qty',
	[tokens.reports.inventory.totalConsumedQty]: 'Total Consumed Qty',
	[tokens.reports.inventory.totalWastageQty]: 'Total Wastage Qty',
	[tokens.reports.inventory.totalDefectQty]: 'Total Defect Qty',
	[tokens.reports.inventory.totalExpiredQty]: 'Total Expired Qty',
	[tokens.reports.inventory.totalAdjustmentQty]: 'Total Adjustment Qty',
	[tokens.reports.inventory.balanceQuantity]: 'Balance Quantity',
	[tokens.reports.inventory.avgCostPerUom]: 'Avg Cost per UoM',
	[tokens.reports.inventory.balanceValue]: 'Balance Value $',
	[tokens.reports.inventory.supplierName]: 'Supplier Name',
	[tokens.reports.inventory.storageLocation]: 'Storage Location',
	[tokens.receipts.landing.dates]: 'Dates',
	[tokens.receipts.landing.ordersUseForMultiples]: 'Orders #, use "," for multiples',
	[tokens.receipts.landing.receiptsUseForMultiples]: 'Receipts #, use "," for multiples',
	[tokens.receipts.landing.receipt]: 'Receipt #',
	[tokens.receipts.landing.createdAt]: 'Created At',
	[tokens.receipts.landing.location]: 'Location',
	[tokens.receipts.landing.paymentType]: 'Payment Type',
	[tokens.receipts.landing.description]: 'Description',
	[tokens.receipts.landing.actions]: 'Actions',
	[tokens.receipts.landing.reprint]: 'Reprint',
	[tokens.receipts.landing.voidReceipt]: 'Void Receipt',
	[tokens.receipts.expandReceiptDetails.receipt]: 'Receipt #',
	[tokens.receipts.expandReceiptDetails.ordersNumbers]: 'Orders Numbers',
	[tokens.receipts.expandReceiptDetails.OfPeople]: '# of People',
	[tokens.receipts.expandReceiptDetails.location]: 'Location',
	[tokens.receipts.expandReceiptDetails.printedDate]: 'Printed Date',
	[tokens.receipts.expandReceiptDetails.orderedDate]: 'Ordered Date',
	[tokens.receipts.expandReceiptDetails.subtotal]: 'Subtotal',
	[tokens.receipts.expandReceiptDetails.offer]: 'Offer',
	[tokens.receipts.expandReceiptDetails.discount]: 'Discount',
	[tokens.receipts.expandReceiptDetails.vat]: 'VAT',
	[tokens.receipts.expandReceiptDetails.serviceCharge]: 'Service Charge',
	[tokens.receipts.expandReceiptDetails.adjustmentValue]: 'Adjustment Value',
	[tokens.receipts.expandReceiptDetails.grandTotal]: 'Grand Total',
	[tokens.receipts.expandReceiptDetails.roundingDifference]: 'Rounding Difference',
	[tokens.receipts.expandReceiptDetails.paymentTypes]: 'Payment Types',
	[tokens.receipts.expandReceiptDetails.cashReceived]: 'Cash Received',
	[tokens.receipts.expandReceiptDetails.cashChanges]: 'Cash Changes',
	[tokens.receipts.expandReceiptDetails.cancelledAmount]: 'Cancelled Amount',
	[tokens.receipts.expandReceiptDetails.paymentNotEqualToGrandTotal]:
		'Payments amount is not equal to grand total',
	[tokens.receipts.expandReceiptDetails.cancelledItems]: 'Cancelled Items',
	[tokens.receipts.expandReceiptDetails.addPaymentType]: 'Add payment type',
	[tokens.orders.landing.dates]: 'Dates',
	[tokens.orders.landing.ordersUseForMultiples]: 'Orders #, use "," for multiples',
	[tokens.orders.landing.createdAt]: 'Created At',
	[tokens.orders.landing.paymentStatus]: 'Payment Status',
	[tokens.orders.landing.description]: 'Description',
	[tokens.orders.landing.orderStatus]: 'Order Status',
	[tokens.orders.landing.copyThisLogId]: 'Copy this log ID',
	[tokens.orders.landing.placed]: 'Placed',
	[tokens.orders.landing.preparing]: 'Preparing',
	[tokens.orders.landing.processed]: 'Processed',
	[tokens.customers.landing.searchPlaceholder]: 'Search by Name, Phone Number, or Customer ID',
	[tokens.customers.landing.newCustomer]: 'New Customer',
	[tokens.customers.landing.creatingNewCustomer]: 'Creating New Customer',
	[tokens.customers.landing.formMessage]: 'Fill out below form to create new customer for your venue',
	[tokens.customers.landing.firstName]: 'First Name',
	[tokens.customers.landing.firstNameIsRequired]: 'First Name is required',
	[tokens.customers.landing.lastName]: 'Last Name',
	[tokens.customers.landing.lastNameIsRequired]: 'Last Name is required',
	[tokens.customers.landing.phoneNumber]: 'Phone Number',
	[tokens.customers.landing.uniqueId]: 'Unique ID',
	[tokens.customers.landing.socials]: 'Socials linked to this phone number',
	[tokens.customers.landing.createdAt]: 'Created At',
	[tokens.customers.landing.fullName]: 'Full Name',
	[tokens.customers.customerDetails.customerInfo]: ' Customer Info',
	[tokens.customers.customerDetails.orderHistory]: 'Orders History',
	[tokens.customers.customerDetails.firstName]: 'First Name',
	[tokens.customers.customerDetails.lastName]: 'Last Name',
	[tokens.customers.customerDetails.phoneNumber]: 'Phone Number',
	[tokens.customers.customerDetails.uniqueId]: 'Unique ID',
	[tokens.customers.customerDetails.dates]: 'Dates',
	[tokens.customers.customerDetails.items]: 'Items',
	[tokens.customers.customerDetails.socials]: 'Socials linked to this phone number',
	[tokens.customers.customerDetails.save]: 'Save',
	[tokens.customers.customerDetails.salesSummary]: 'Sales Summary',
	[tokens.customers.customerDetails.grossAmount]: 'Gross Amount',
	[tokens.customers.customerDetails.ordersCount]: 'Orders Count',
	[tokens.customers.customerDetails.discount]: 'Discount',
	[tokens.customers.customerDetails.columns]: 'Columns',
	[tokens.customers.customerDetails.title]: 'Title',
	[tokens.customers.customerDetails.soldCount]: 'Sold Count',
	[tokens.customers.customerDetails.hideAll]: 'Hide All',
	[tokens.customers.customerDetails.showAll]: 'Show All',
	[tokens.customers.customerDetails.filters]: 'Filters',
	[tokens.customers.customerDetails.operator]: 'Operator',
	[tokens.customers.customerDetails.value]: 'Value',
	[tokens.customers.customerDetails.contains]: 'Contains',
	[tokens.customers.customerDetails.equals]: 'Equals',
	[tokens.customers.customerDetails.startsWith]: 'Starts With',
	[tokens.customers.customerDetails.endsWith]: 'Ends With',
	[tokens.customers.customerDetails.isEmpty]: 'Is Empty',
	[tokens.customers.customerDetails.isNotEmpty]: 'Is Not Empty',
	[tokens.customers.customerDetails.isAnyOf]: 'Is Any Of',
	[tokens.customers.customerDetails.density]: 'Density',
	[tokens.customers.customerDetails.compact]: 'Compact',
	[tokens.customers.customerDetails.standard]: 'Standard',
	[tokens.customers.customerDetails.comfortable]: 'Comfortable',
	[tokens.customers.customerDetails.export]: 'Export',
	[tokens.customers.customerDetails.downloadAsCsv]: 'Download as CSV',
	[tokens.customers.customerDetails.print]: 'Print',
	[tokens.customers.customerDetails.sortByAsc]: 'Sort by ASC',
	[tokens.customers.customerDetails.sortByDesc]: 'Sort by DESC',
	[tokens.customers.customerDetails.showColumns]: 'Show columns',
	[tokens.customers.customerDetails.pinToLeft]: 'Pin to left',
	[tokens.customers.customerDetails.pinToRight]: 'Pin to right',
	[tokens.customers.customerDetails.noResultsFound]: 'No results found.',
	[tokens.customers.customerDetails.rowsPerPage]: 'Rows per page',
	[tokens.customers.customerDetails.createdAt]: 'Created At',
	[tokens.customers.customerDetails.paymentStatus]: 'Payment Status',
	[tokens.customers.customerDetails.description]: 'Description',
	[tokens.customers.customerDetails.orderStatus]: 'Order Status',
	[tokens.offers.landing.title]: 'Title',
	[tokens.offers.landing.newOffer]: 'New Offer',
	[tokens.offers.landing.createdAt]: 'Created At',
	[tokens.offers.landing.summary]: 'Summary',
	[tokens.offers.landing.creatingNewOffer]: 'Creating New Offer',
	[tokens.offers.landing.formMessage]: 'Fill out below form to create new offer for your venue',
	[tokens.offers.landing.offerTitle]: 'Offer Title',
	[tokens.offers.landing.tileIsRequired]: 'Tile is required',
	[tokens.offers.landing.applyHigherValue]: 'Apply higher value',
	[tokens.offers.landing.applyHighestValueMessage]:
		'If an item is qualified for 2 or more criterias, apply the highest value criteria',
	[tokens.offers.landing.applyLowestValueMessage]:
		'If an item is qualified for 2 or more criterias, apply the LOWEST value criteria',
	[tokens.offers.landing.addConfig]: 'Add Config',
	[tokens.offers.landing.byReceipts]: 'By Receipts',
	[tokens.offers.landing.useWithOtherConfigTypes]: "You can't use this with other config types",
	[tokens.offers.landing.byItems]: 'By Items',
	[tokens.offers.landing.byCategories]: 'By Categories',
	[tokens.offers.landing.byItemTypes]: 'By Item Types',
	[tokens.offers.landing.discountByReceipts]: 'Discount by Receipts',
	[tokens.offers.landing.discountByItems]: 'Discount by Items',
	[tokens.offers.landing.applicableItems]: 'Applicable Items',
	[tokens.offers.landing.discountByCategories]: 'Discount by categories',
	[tokens.offers.landing.applicableCategories]: 'Applicable categories',
	[tokens.offers.landing.discountValue]: 'Discount Value',
	[tokens.offers.landing.discountByItemTypes]: 'Discount By Item Types',
	[tokens.offers.landing.applicableItemTypes]: 'Applicable Types',
	[tokens.offers.offerDetails.offerInfo]: 'Offer Info',
	[tokens.offers.offerDetails.offerName]: 'Offer Name',
	[tokens.offers.offerDetails.offerStatus]: 'Offer Status',
	[tokens.offers.offerDetails.active]: 'Active',
	[tokens.offers.offerDetails.configSummary]: 'Config Summary',
	[tokens.offers.offerDetails.offerHigherValueUponCollision]: 'Offer higher value upon collision',
	[tokens.offers.offerDetails.offerLowerValueUponCollision]: 'Offer lower value upon collision',
	[tokens.offers.offerDetails.editThisOffer]: 'Edit this offer',
	[tokens.offers.offerDetails.deactivate]: 'Deactivate',
	[tokens.offers.offerDetails.offerUsageSummary]: 'Offer Usage Summary',
	[tokens.offers.offerDetails.ordersCount]: 'Orders Count',
	[tokens.offers.offerDetails.usageAmount]: 'USage Amount',
	[tokens.offers.offerDetails.ordersHistory]: 'Orders History',
	[tokens.offers.offerDetails.dates]: 'Dates',
	[tokens.offers.offerDetails.createdAt]: 'Created At',
	[tokens.offers.offerDetails.paymentStatus]: 'Payment Status',
	[tokens.offers.offerDetails.description]: 'Description',
	[tokens.offers.offerDetails.orderStatus]: 'Order Status',
	[tokens.offers.offerDetails.copyThisLogId]: 'Copy this log ID',
	[tokens.offers.offerDetails.criterias]: 'Criterias',
	[tokens.offers.offerDetails.updatingOffer]: 'Updating Offer',
	[tokens.offers.offerDetails.formHeaderDesc]: 'Fill out below form to create new offer for your venue',
	[tokens.offers.offerDetails.youCanTUseThisWithOtherConfigTypes]:
		"You can't use this with other config types",
	[tokens.reports.summary.salesSummary]: 'Sales Summary',
	[tokens.settings.nav.general]: 'General',
	[tokens.settings.nav.ordering]: 'Ordering',
	[tokens.settings.nav.printing]: 'Printing',
	[tokens.settings.nav.customs]: 'Customs',
	[tokens.settings.nav.userPermissions]: 'User Permissions',
	[tokens.settings.nav.addOns]: 'Add Ons',
	[tokens.settings.general.name]: 'Name',
	[tokens.settings.general.description]: 'Description',
	[tokens.settings.general.address]: 'Address',
	[tokens.settings.general.vatTin]: 'VAT TIN',
	[tokens.settings.ordering.requireCustomerForDigitalMenuOrders]: 'Require Customer for Digital Menu Orders',
	[tokens.settings.ordering.enableOrderCommentsForDigitalMenuOrders]:
		'Enable Order Comments for digital menu orders',
	[tokens.settings.ordering.digitalMenuLabel]: 'Enable order confirmation before payment for digital menu',
	[tokens.settings.ordering.noteLabel]: 'Require note for digital menu orders',
	[tokens.settings.ordering.noteInstructionLabel]: 'What should be note input instruction?',
	[tokens.settings.ordering.inputTheNoteInstructions]: 'Input the note instructions',
	[tokens.settings.ordering.orderNotificationDings]: 'Activate alarm for digital menu orders',
	[tokens.settings.ordering.orderNotificationDingsHelper]: 'Input the amount of alarm rings (per interval)',
	[tokens.settings.ordering.addVat]: 'Add VAT',
	[tokens.settings.ordering.howVatShouldBeCharged]: 'How VAT should be charged?',
	[tokens.settings.ordering.separatelyAsLineItem]: 'Separately as line item',
	[tokens.settings.ordering.vatShowMessage]:
		'VAT will be shown to your consumers on the checkout page as a separate VAT charges',
	[tokens.settings.ordering.vatBuriedMessage]:
		'VAT will be attributed to all your items and options price, and your consumers will not see a VAT charges',
	[tokens.settings.ordering.addServiceCharge]: 'Add service charge',
	[tokens.settings.ordering.howMuchForServiceCharge]: 'How much for service charge',
	[tokens.settings.ordering.serviceFeeNote]: 'Note that the above amount will include 5% Served fee',
	[tokens.settings.ordering.howServiceShouldCharged]: 'How service charge should be charged?',
	[tokens.settings.ordering.buriedInItemsAndOptionsPrice]: 'Buried in items and options price',
	[tokens.settings.ordering.buriedInItemsAndOptionsPrice]: 'Buried in items and options price',
	[tokens.settings.ordering.serviceChargeShowMessage]:
		'Service charge will be shown to your consumers on the checkout page as a separate service charge',
	[tokens.settings.ordering.serviceChargeBuriedMessage]:
		'Service charge will be attributed to all your items and options price, and your consumers will not see a service charge',
	[tokens.settings.ordering.opsPageFontSize]: 'Operation page font size (pixels)',
	[tokens.settings.ordering.opsPageFontsSizePx]: 'Font size (pixels)',
	[tokens.settings.printing.printServiceStatus]: 'Print service status',
	[tokens.settings.printing.printServiceStatusMessage]: 'Print service is offline for this terminal',
	[tokens.settings.printing.printOrderChecklist]: 'Print order checklist',
	[tokens.settings.printing.printTicketByItem]: 'Print ticket by item',
	[tokens.settings.printing.printTicketByOption]: 'Print ticket by option',
	[tokens.settings.printing.printContentPaddingPixels]: 'Print content padding (pixels)',
	[tokens.settings.printing.topPx]: 'Top (px)',
	[tokens.settings.printing.rightPx]: 'Right (px)',
	[tokens.settings.printing.bottomPx]: 'Bottom (px)',
	[tokens.settings.printing.leftPx]: 'Left (px)',
	[tokens.settings.printing.printFontSizesPixels]: 'Print font sizes (pixels)',
	[tokens.settings.printing.ticketChecklistMessagePx]: 'Ticket, checklist, message (px)',
	[tokens.settings.printing.billReceiptPx]: 'Bill, Receipt (px)',
	[tokens.settings.printing.reportsPx]: 'Reports (px)',
	[tokens.settings.printing.createNewStation]: 'Create new station',
	[tokens.settings.printing.createNewWorkStation]: 'Create new work station',
	[tokens.settings.printing.updateWorkStation]: 'Update work station',
	[tokens.settings.printing.whatShouldBeTheStationName]: 'What should be the station name?',
	[tokens.settings.printing.selectAPrinterForThisStation]: 'Select a printer for this station',
	[tokens.settings.printing.itemTypesOfInterest]: 'Item types of interest',
	[tokens.settings.printing.printerTagsOfInterest]: 'Printer tags of interest',
	[tokens.settings.printing.locationsOfInterest]: 'Locations of interest',
	[tokens.settings.printing.cancel]: 'Cancel',
	[tokens.settings.printing.save]: 'Save',
	[tokens.settings.printing.stationName]: 'Station Name',
	[tokens.settings.printing.usingPrinter]: 'Using Printer',
	[tokens.settings.printing.manage]: 'Manage',
	[tokens.settings.printing.kitchenMessage]: 'Kitchen message',
	[tokens.settings.printing.ticket]: 'ticket',
	[tokens.settings.printing.receipt]: 'receipt',
	[tokens.settings.printing.checklist]: 'checklist',
	[tokens.settings.printing.report]: 'report',
	[tokens.settings.printing.cancelledOrderTicket]: 'Cancelled order ticket',
	[tokens.settings.printing.delete]: 'Delete',
	[tokens.settings.customs.customPrintingFields]: 'Custom Printing Fields',
	[tokens.settings.customs.typeAndPressEnter]: 'Type and press enter',
	[tokens.settings.customs.availableItemTypes]: 'Available item types',
	[tokens.settings.customs.customPaymentTypes]: 'Custom payment types',
	[tokens.settings.customs.shifts]: 'Shifts',
	[tokens.settings.customs.locationGroupTags]: 'Location Group Tags',
	[tokens.settings.customs.printerTagsOfInterest]: 'Printer tags of interest',
	[tokens.settings.customs.orderCancelReasons]: 'Order Cancel Reasons',
	[tokens.settings.customs.presetMessages]: 'Preset Messages',
	[tokens.settings.customs.semicolonIsAReservedCharacter]: 'Semicolon (;) is a reserved character',
	[tokens.settings.customs.currenciesConfiguration]: 'Currencies Configuration',
	[tokens.settings.customs.currencyCode]: 'Currency Code',
	[tokens.settings.customs.rateToPrimaryCurrency]: 'Rate to primary currency',
	[tokens.settings.customs.primary]: 'Primary',
	[tokens.settings.customs.secondary]: 'Secondary',
	[tokens.settings.customs.deactivate]: 'Deactivate',
	[tokens.settings.customs.addCurrency]: 'Add Currency',
	[tokens.settings.customs.createCurrencyConfiguration]: 'Create currency configuration',
	[tokens.settings.customs.rateComparedToPrimaryCurrency]: 'Rate compared to primary currency',
	[tokens.settings.userPermissions.searchUsername]: 'Search Username',
	[tokens.settings.userPermissions.reFetchUsers]: 'Re-Fetch Users',
	[tokens.settings.userPermissions.ORDER_MANAGEMENT_FEATURE]: 'Order Management Feature',
	[tokens.settings.userPermissions.VIEW_ORDER]: 'View Order',
	[tokens.settings.userPermissions.CREATE_ORDER]: 'Create Order',
	[tokens.settings.userPermissions.EDIT_ORDER_BEFORE_BILL_PRINTED]: 'Edit Order before bill printed',
	[tokens.settings.userPermissions.EDIT_ORDER_AFTER_BILL_PRINTED]: 'Edit order after bill printed',
	[tokens.settings.userPermissions.EDIT_ORDER_BEFORE_PAYMENT]: 'Edit order before payment',
	[tokens.settings.userPermissions.EDIT_ORDER_AFTER_PAYMENT]: 'Edit order after payment',
	[tokens.settings.userPermissions.CANCEL_ORDER_BEFORE_BILL_PRINTED]: 'Cancel order before bill printed',
	[tokens.settings.userPermissions.CANCEL_ORDER_AFTER_BILL_PRINTED]: 'Cancel order after bill printed',
	[tokens.settings.userPermissions.CANCEL_ORDER_BEFORE_PAYMENT]: 'Cancel order before payment',
	[tokens.settings.userPermissions.CANCEL_ORDER_AFTER_PAYMENT]: 'Cancel order after payment',
	[tokens.settings.userPermissions.RECEIPT_MANAGEMENT]: 'Receipt Management',
	[tokens.settings.userPermissions.CANCEL_RECEIPT]: 'Cancel receipt',
	[tokens.settings.userPermissions.USER_SETTINGS]: 'User Settings',
	[tokens.settings.userPermissions.AUTO_LOGOUT]: 'Auto Logout',
	[tokens.settings.addOns.consumerAppTheme]: 'Consumer App Theme',
	[tokens.settings.addOns.shouldAggregateTipsIntoTotalGrossSales]:
		'Should aggregate tips into total gross sales',
	[tokens.settings.addOns.primaryColor]: 'Primary Color',
	[tokens.settings.addOns.primaryTextColor]: 'Primary Text Color',
	[tokens.settings.addOns.enableInventoryMangement]: 'Enable inventory mangement',
	[tokens.settings.addOns.enablePurchaseManagement]: 'Enable purchase management',
	[tokens.settings.addOns.enablePaymentRounding]: 'Enable payment rounding',
	[tokens.settings.addOns.venueOnlyUseDigitalMenu]: 'Venue only use digital menu',
	[tokens.settings.addOns.paywayLabel]: 'Whether venue has PayWay integration enabled:',
	[tokens.settings.addOns.enableInternalReceipt]: 'Enable internal receipt',
	[tokens.settings.addOns.enableLanguageLocalization]: 'Enable Language Localization',
	[tokens.settings.addOns.requireHeadcountBeforePayment]: 'Require headcount before payment',
	[tokens.settings.addOns.opsIntervalLabel]: 'Operational Data Refectch Interval (minutes)',
	[tokens.settings.addOns.enableRoomMasterIntegration]: 'Enable Room Master Integration',
	[tokens.settings.addOns.setUpRoomMasterPropertyId]: 'Set up Room Master Property ID',
	[tokens.settings.addOns.yes]: 'Yes',
	[tokens.settings.addOns.no]: 'No',
	[tokens.printservice.staff]: 'Staff',
	[tokens.printservice.print]: 'Print',
	[tokens.printservice.ordered]: 'Ordered',
	[tokens.printservice.orders]: 'Orders #',
	[tokens.printservice.qty]: 'Qty',
	[tokens.printservice.item]: 'Item',
	[tokens.printservice.price]: 'Price',
	[tokens.printservice.subtotal]: 'Subtotal',
	[tokens.printservice.vat]: 'VAT',
	[tokens.printservice.serviceCharge]: 'Service Charge',
	[tokens.printservice.grandTotal]: 'Grand Total',
	[tokens.printservice.receipt]: 'Receipt#',
	[tokens.printservice.paymentTypes]: 'Payment Types',
	[tokens.printservice.cashReceived]: 'Cash Received',
	[tokens.printservice.cashChanges]: 'Cash Changes',
	[tokens.printservice.received]: 'Received',
	[tokens.global.bill]: 'Bill',
	[tokens.global.location]: 'Location',
	[tokens.global.orders]: 'Orders',
	[tokens.global.placed]: 'Placed',
	[tokens.global.preparing]: 'Preparing',
	[tokens.global.processed]: 'Processed',
	[tokens.global.proceed]: 'Proceed',
	[tokens.global.unpaid]: 'UNPAID',
	[tokens.global.paid]: 'PAID',
	[tokens.global.pay]: 'Pay',
	[tokens.global.payNow]: 'Pay Now',
	[tokens.global.payLater]: 'Pay Later',
	[tokens.global.move]: 'Move',
	[tokens.global.of]: 'of',
	[tokens.global.not]: 'Not',
	[tokens.global.all]: 'All',
	[tokens.global.allowed]: 'Allowed',
	[tokens.global.details]: 'Details',
	[tokens.global.view]: 'View',
	[tokens.global.add]: 'Add',
	[tokens.global.create]: 'Create',
	[tokens.global.edit]: 'Edit',
	[tokens.global.update]: 'Update',
	[tokens.global.save]: 'Save',
	[tokens.global.yes]: 'YES',
	[tokens.global.no]: 'NO',
	[tokens.global.delete]: 'Delete',
	[tokens.global.send]: 'Send',
	[tokens.global.undo]: 'Undo',
	[tokens.global.back]: 'Back',
	[tokens.global.close]: 'Close',
	[tokens.global.done]: 'Done',
	[tokens.global.cancel]: 'Cancel',
	[tokens.global.cancelled]: 'Cancelled',
	[tokens.global.subTotal]: 'Sub Total',
	[tokens.global.offer]: 'Offer',
	[tokens.global.discount]: 'Discount',
	[tokens.global.vat]: 'VAT',
	[tokens.global.service]: 'Service',
	[tokens.global.adjustment]: 'Adjustment',
	[tokens.global.grandTotal]: 'Grand Total',
	[tokens.global.presetMessages]: 'Preset Messages',
	[tokens.global.logout]: 'Log Out',
	[tokens.global.manage]: 'Manage',
	[tokens.global.copy]: 'Copy',
	[tokens.global.addThisOption]: 'Add this option',
	[tokens.global.cancelReason]: 'Cancel Reason',
	[tokens.global.cancelReasonIsRequired]: 'Cancellation reason is required',
	[tokens.global.createAndReturnToTill]: 'Create and return to till',
};
