import { GraphQLClient } from 'graphql-request';
import { RequestMiddleware, Response } from 'graphql-request/build/esm/types';
import { ClientOptions, createClient } from 'graphql-ws';
import i18n from 'i18next';

import { getState, setState } from '~/store';

import { browserStorage } from './localforage';

export { getHubGqlWsClient, hubGqlClient };

const requestMiddleware: RequestMiddleware = async (request) => {
	const authHeaders = await getAuthHeaders();
	return { ...request, headers: { ...request.headers, ...authHeaders } };
};

const responseMiddleware = async (response: Response<unknown> | Error) => {
	if (response?.['response']?.['errors'] && response?.['response']?.['errors']?.length) {
		const messages = response['response']['errors']
			.map((e) => e['message'])
			.filter(Boolean)
			.join('\n');

		console.log('=== GQL CLIENT ERROR ===', messages);
		setState({ error: messages || '🤧 Something went wrong' });
	}
};

const hubGqlClient = new GraphQLClient(process.env.HUB_GQL_URL!, { requestMiddleware, responseMiddleware });

const getAuthHeaders = async () => {
	const obj = {} as Record<string, string>;

	const { venueId, token: _token } = getState();
	const token = _token || (await browserStorage.token.get());

	if (token && obj['Authorization'] === undefined) obj['Authorization'] = `Bearer ${token}`;
	if (venueId) obj['x-served-venue-id'] = venueId;
	obj['x-served-staff-app-signature'] = new Date().getTime().toString();
	obj['x-served-laguage'] = i18n.language || 'en';

	return obj;
};

const getHubGqlWsClient = () => {
	return createClient({
		url: process.env.HUB_GQL_WS_URL!,
		keepAlive: 5000,
		shouldRetry: () => true,
		retryAttempts: Infinity,
		retryWait: () => new Promise((resolve) => setTimeout(resolve, 2000)),
		connectionParams: getAuthHeaders,
		on: getWsClientLogger(),
	});
};

let timer: NodeJS.Timeout | null = null;

export const getWsClientLogger = (): ClientOptions['on'] => {
	return {
		connected: (...e) => {
			if (timer) clearTimeout(timer);

			setState({ isConnectedToServer: true });
			if (process.env.DEBUG) console.log(`🚀 ~ connected ~ e:`, e);
		},
		closed: (...e) => {
			if (process.env.DEBUG) console.log(`🚀 ~ closed ~ e:`, e);
			// Socket could be forcibly terminated by the client since WS params could be changed
			timer = setTimeout(() => {
				if (!getState().isConnectedToServer) setState({ isConnectedToServer: false });
			}, 5000);
		},
		error: (...e) => {
			setState({ isConnectedToServer: false });
			if (process.env.DEBUG) console.log(`🚀 ~ error ~ e:`, e);
		},
		// connecting: () => {},
		// message: () => {},
		// opened: () => {},
		// ping: () => {},
		// pong: () => {},
	};
};
