export const DEFAULT_PAGINATION_LIMIT = 20;

export const getTotalPages = ({ total, size }: { total: number; size?: number }) =>
	Math.ceil(total / (size || DEFAULT_PAGINATION_LIMIT));

export const getCurrentPageFromStartIndex = ({ startIndex, size }: { startIndex: number; size?: number }) =>
	Math.ceil(startIndex / (size || DEFAULT_PAGINATION_LIMIT)) + 1;

export const getNextStartIndexFromPage = ({ page, size }: { page: number; size?: number }) =>
	(page - 1) * (size || DEFAULT_PAGINATION_LIMIT);
