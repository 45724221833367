import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { useOrdersIsLoading, useSetOrdersIsLoading } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { GetOperationalOrdersQuery } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	query GetOperationalOrders {
		getOperationalOrders {
			placed {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				user
				customer
				venue
				status
				is_paid
				payway_ref
				payway_verification_data
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				items {
					_id
					category
					item
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}

				_staff {
					_id
					first_name
					last_name
				}
				_prepared_by {
					_id
					first_name
					last_name
				}
				_processed_by {
					_id
					first_name
					last_name
				}
				_paid_by {
					_id
					first_name
					last_name
				}
				_cancelled_by {
					_id
					first_name
					last_name
				}
				_location {
					_id
					name
					type
					hash
				}
				_current_location {
					_id
					name
					type
					hash
				}
				_customer {
					_id
					first_name
					last_name
				}
				_receipt {
					_id
					payment_types {
						amount
						payment_type
						code
					}
				}
			}
			preparing {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				user
				customer
				venue
				status
				is_paid
				payway_ref
				payway_verification_data
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				items {
					_id
					category
					item
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}

				_staff {
					_id
					first_name
					last_name
				}
				_prepared_by {
					_id
					first_name
					last_name
				}
				_processed_by {
					_id
					first_name
					last_name
				}
				_paid_by {
					_id
					first_name
					last_name
				}
				_cancelled_by {
					_id
					first_name
					last_name
				}
				_location {
					_id
					name
					type
					hash
				}
				_current_location {
					_id
					name
					type
					hash
				}
				_customer {
					_id
					first_name
					last_name
				}
				_receipt {
					_id
					payment_types {
						amount
						payment_type
						code
					}
				}
			}
			processed {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				user
				customer
				venue
				status
				is_paid
				payway_ref
				payway_verification_data
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				items {
					_id
					category
					item
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}

				_staff {
					_id
					first_name
					last_name
				}
				_prepared_by {
					_id
					first_name
					last_name
				}
				_processed_by {
					_id
					first_name
					last_name
				}
				_paid_by {
					_id
					first_name
					last_name
				}
				_cancelled_by {
					_id
					first_name
					last_name
				}
				_location {
					_id
					name
					type
					hash
				}
				_current_location {
					_id
					name
					type
					hash
				}
				_customer {
					_id
					first_name
					last_name
				}
				_receipt {
					_id
					payment_types {
						amount
						payment_type
						code
					}
				}
			}
		}
	}
`;

export type GetOperationalOrdersResults = GetOperationalOrdersQuery['getOperationalOrders'];

export const getOperationalOrdersKey = () => ['getOperationalOrders'];

export const getOperationalOrdersQueryFn = () =>
	hubGqlClient.request(graphql(query)).then((res) => res.getOperationalOrders);

export const initialData: GetOperationalOrdersResults = {
	placed: [],
	preparing: [],
	processed: [],
};

export const useGetOperationalOrders = (enabled?: boolean) => {
	const ordersIsLoading = useOrdersIsLoading();
	const setOrdersIsLoading = useSetOrdersIsLoading();

	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOperationalOrdersKey(),
		queryFn: getOperationalOrdersQueryFn,
		onSuccess: () => {
			if (ordersIsLoading) setOrdersIsLoading(false);
		},
		initialData,
	});
};

export const getOrdersCache = () => {
	return (
		window.$queryClient.getQueryData<GetOperationalOrdersResults>(getOperationalOrdersKey(), {
			exact: true,
		}) || initialData
	);
};
