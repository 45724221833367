import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { AppProps } from 'next/app';
import Router from 'next/router';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import 'react-simple-keyboard/build/css/index.css';
import { useBoolean, useEffectOnce, useEventListener } from 'usehooks-ts';
import { ErrorBoundary, SplashScreen } from '~served/ui-comps';
import { AppUpdateChecker } from '~/components/AppUpdateChecker';
import { ErrorAlert } from '~/components/ErrorAlert';
import { FeatureRestrictionChecker } from '~/components/FeatureRestrictionChecker';
import { InfoAlert } from '~/components/InfoAlert';
import { LogoutModal } from '~/components/LogoutModal';
import { OfflineDialog } from '~/components/OfflineDialog';
import { OperationalNotification } from '~/components/OperationalNotification';
import { PushNotification } from '~/components/PushNotification';
import { useRouterEvent } from '~/libs/google-analytics';
import { useNprogress } from '~/libs/nprogrss';
import { AppProvider } from '~/providers/AppProvider';
import { QueryProvider } from '~/providers/QueryProvider';
import { useIsReady, useResetApp } from '~/store';
import '../locales/i18n';
export default function MyApp({
  Component,
  pageProps,
  router: appRouter
}: AppProps) {
  const {
    value: isMounted,
    setTrue: setMounted
  } = useBoolean(false);
  const resetApp = useResetApp();
  useEffectOnce(() => {
    setMounted();
  });
  useEventListener('unload', () => {
    resetApp();
    window.$queryClient?.clear?.();
  });
  useEffect(() => {
    const onReceiveServiceWorkerMessage = (event: MessageEvent) => {
      if (!event.data.action) return;
      switch (event.data.action) {
        case 'redirect-from-notificationclick':
          Router.replace(event.data.url);
          break;
      }
    };
    if (navigator.serviceWorker) navigator.serviceWorker.addEventListener('message', onReceiveServiceWorkerMessage);
    return () => {
      if (navigator.serviceWorker) navigator.serviceWorker.removeEventListener('message', onReceiveServiceWorkerMessage);
    };
  }, []);
  useNprogress(appRouter);
  useRouterEvent();
  const isReady = useIsReady();
  if (!isMounted) return null;
  return <ErrorBoundary appName="STAFF APP" id="1239419474851926046" token="MtDfsszAvQR3NoKauliuvWRd0pW3Ck5pDioUFlIeYrvHS1pE-B-VIAwzLSbVncZv24iS" data-sentry-element="ErrorBoundary" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
			<QueryProvider data-sentry-element="QueryProvider" data-sentry-source-file="_app.tsx">
				<AppProvider data-sentry-element="AppProvider" data-sentry-source-file="_app.tsx">
					<SnackbarProvider anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} preventDuplicate maxSnack={100} data-sentry-element="SnackbarProvider" data-sentry-source-file="_app.tsx">
						<OfflineDialog data-sentry-element="OfflineDialog" data-sentry-source-file="_app.tsx" />
						<LogoutModal data-sentry-element="LogoutModal" data-sentry-source-file="_app.tsx" />
						<InfoAlert data-sentry-element="InfoAlert" data-sentry-source-file="_app.tsx" />
						<ErrorAlert data-sentry-element="ErrorAlert" data-sentry-source-file="_app.tsx" />
						<AppUpdateChecker data-sentry-element="AppUpdateChecker" data-sentry-source-file="_app.tsx" />
						<FeatureRestrictionChecker data-sentry-element="FeatureRestrictionChecker" data-sentry-source-file="_app.tsx" />
						<PushNotification data-sentry-element="PushNotification" data-sentry-source-file="_app.tsx" />
						<OperationalNotification data-sentry-element="OperationalNotification" data-sentry-source-file="_app.tsx" />

						{isReady ? <Component {...pageProps} /> : <SplashScreen />}
					</SnackbarProvider>
				</AppProvider>
			</QueryProvider>
		</ErrorBoundary>;
}