import { useQuery, useQueryClient } from '@tanstack/react-query';

import { LoginResults } from '../useLogin';

// TODO: See if we can remove this and use only useLogin results

export type AuthResults = LoginResults['role'];

export const getAuthQueryKey = () => ['auth'];

export const useAuthCache = () => {
	const queryClient = useQueryClient();

	return useQuery({
		queryKey: getAuthQueryKey(),
		queryFn: () => queryClient.getQueryData<AuthResults>(getAuthQueryKey(), { exact: true }) || null,
	});
};

export const getAuthCache = () => {
	return window.$queryClient.getQueryData<AuthResults>(getAuthQueryKey(), { exact: true }) || null;
};
