import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { GetOffersQuery } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	query GetOffers {
		getOffers {
			_id
			created_at
			updated_at
			title
			is_offering_higher_value
			is_deactivated
			configs {
				type
				discount_type
				discount_value
				item_types
				items
				categories
			}
		}
	}
`;

export type GetOffersResults = GetOffersQuery['getOffers'];

export const getOffersQueryKey = () => ['getOffers'];

export const useGetOffers = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOffersQueryKey(),
		queryFn: () => hubGqlClient.request(graphql(query)).then((res) => res.getOffers),
	});
};

export const useGetOffersCache = () => {
	return useGetOffers(false);
};
